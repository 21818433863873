import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import productStyle from 'assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.jsx'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
import firebase from '../firebase'
import Email from '@material-ui/icons/Email'
import InputAdornment from '@material-ui/core/InputAdornment'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff0066;
  min-height: 100vh;
  flex-direction: column;
  margin-top: -10%;
`

const Header = styled.h1`
  font-size: 5vw;
  color: #ffffff;
  padding: 5% 0 3% 0;
  font-weight: bold;
  text-align: center;
  border-radius: 16px;
`

const Button = styled.button`
  background: #ffffff;
  color: #ff0066;
  font-size: 30px;
  padding: 5px 40px;
  text-transform: uppercase;
  font-weight: bold;
  width: 200px;
  margin: 5% auto 1% auto;
`

const ForogotPassword = ({ classes }) => {
  const [resetPasswordEmail, setResetPasswordEmail] = React.useState('')
  const [isPasswordReset, setIsPasswordReset] = React.useState(false)
  const [passwordResetError, setPasswordResetError] = React.useState(null)

  const handleResetPassowrd = async () => {
    try {
      await firebase.resetPassword(resetPasswordEmail)
      setIsPasswordReset(true)
      setPasswordResetError(null)
    } catch (err) {
      setPasswordResetError(err.message)
      setIsPasswordReset(false)
    }
  }
  return (
    <Wrapper>
      <div>
        <Header>Forgot Password ?</Header>
      </div>

      <div>
        <CustomInput
          id="email"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            placeholder: 'Enter your email address...',
            type: 'email',
            startAdornment: (
              <InputAdornment position="start">
                <Email className={classes.inputIconsColor} />
              </InputAdornment>
            ),
            onChange: e => setResetPasswordEmail(e.target.value),
          }}
        />

        <div className={classes.textCenter}>
          <Button onClick={() => handleResetPassowrd()}>Submit</Button>
        </div>
        {isPasswordReset && (
          <p className="text">Check email to reset password...</p>
        )}
        {passwordResetError && (
          <p className="error-text">{passwordResetError}</p>
        )}
      </div>
    </Wrapper>
  )
}

export default withStyles(productStyle)(ForogotPassword)
