import React, { Component } from 'react'
import ForgotPassword from '../components/ForgotPassword'
import Layout from '../components/layout'


export default class editProfilePage extends Component {
  render() {
    return (
      <Layout>
        <ForgotPassword/>
      </Layout>
    )
  }
}